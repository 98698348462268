import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/ConfigApp'

export const publicRoutes = [
  {
    key: 'signin',
    path: `${AUTH_PREFIX_PATH}/signin`,
    component: React.lazy(() => import('presentation/pages/authentication/signin/SigninIndex')),
  },
  {
    key: 'page-not-found',
    path: `${AUTH_PREFIX_PATH}/page-not-found`,
    component: React.lazy(() =>
      import('presentation/pages/authentication/page-not-found/PageNotFoundIndex'),
    ),
  },
]

export const protectedRoutes = [
  // Home
  {
    key: 'home',
    path: `/`,
    component: React.lazy(() => import('presentation/pages/home/HomeIndex')),
  },

  // ACCOUNT
  {
    key: 'account.profile.view',
    path: `${APP_PREFIX_PATH}/account/profile`,
    component: React.lazy(() => import('presentation/pages/account/profile/ProfileView')),
  },
  {
    key: 'account.profile.update',
    path: `${APP_PREFIX_PATH}/account/update`,
    component: React.lazy(() => import('presentation/pages/account/profile/ProfileUpdate')),
  },
  {
    key: 'account.profile.change-password',
    path: `${APP_PREFIX_PATH}/account/change-password`,
    component: React.lazy(() => import('presentation/pages/account/profile/ProfileChangePassword')),
  },
  {
    key: 'account.notification.manage',
    path: `${APP_PREFIX_PATH}/account/notification`,
    component: React.lazy(() =>
      import('presentation/pages/account/notification/AccountNotificationIndex'),
    ),
  },

  // PARTNER - ACCOUNT
  {
    key: 'partner.account.manage',
    path: `${APP_PREFIX_PATH}/partner/account/manage`,
    component: React.lazy(() => import('presentation/pages/partner/account/PartnerAccountIndex')),
  },
  {
    key: 'partner.account.create',
    path: `${APP_PREFIX_PATH}/partner/account/create`,
    component: React.lazy(() => import('presentation/pages/partner/account/PartnerAccountCreate')),
  },
  {
    key: 'partner.account.update',
    path: `${APP_PREFIX_PATH}/partner/account/update`,
    component: React.lazy(() => import('presentation/pages/partner/account/PartnerAccountUpdate')),
  },
  {
    key: 'partner.account.view',
    path: `${APP_PREFIX_PATH}/partner/account/view`,
    component: React.lazy(() => import('presentation/pages/partner/account/PartnerAccountView')),
  },

  // PARTNER - EMPLOYEE
  {
    key: 'partner.employee.manage',
    path: `${APP_PREFIX_PATH}/partner/employee/manage`,
    component: React.lazy(() => import('presentation/pages/partner/employee/PartnerEmployeeIndex')),
  },
  {
    key: 'partner.employee.create',
    path: `${APP_PREFIX_PATH}/partner/employee/create`,
    component: React.lazy(() =>
      import('presentation/pages/partner/employee/PartnerEmployeeCreate'),
    ),
  },
  {
    key: 'partner.employee.update',
    path: `${APP_PREFIX_PATH}/partner/employee/update`,
    component: React.lazy(() =>
      import('presentation/pages/partner/employee/PartnerEmployeeUpdate'),
    ),
  },
  {
    key: 'partner.employee.view',
    path: `${APP_PREFIX_PATH}/partner/employee/view`,
    component: React.lazy(() => import('presentation/pages/partner/employee/PartnerEmployeeView')),
  },

  // PARTNER - LOCATION
  {
    key: 'partner.location.manage',
    path: `${APP_PREFIX_PATH}/partner/location/manage`,
    component: React.lazy(() => import('presentation/pages/partner/location/PartnerLocationIndex')),
  },
  {
    key: 'partner.location.create',
    path: `${APP_PREFIX_PATH}/partner/location/create`,
    component: React.lazy(() =>
      import('presentation/pages/partner/location/PartnerLocationCreate'),
    ),
  },
  {
    key: 'partner.location.update',
    path: `${APP_PREFIX_PATH}/partner/location/update`,
    component: React.lazy(() =>
      import('presentation/pages/partner/location/PartnerLocationUpdate'),
    ),
  },
  {
    key: 'partner.location.view',
    path: `${APP_PREFIX_PATH}/partner/location/view`,
    component: React.lazy(() => import('presentation/pages/partner/location/PartnerLocationView')),
  },

  // PARTNER - ASSET
  {
    key: 'partner.asset.manage',
    path: `${APP_PREFIX_PATH}/partner/asset/manage`,
    component: React.lazy(() => import('presentation/pages/partner/asset/PartnerAssetIndex')),
  },
  {
    key: 'partner.asset.create',
    path: `${APP_PREFIX_PATH}/partner/asset/create`,
    component: React.lazy(() => import('presentation/pages/partner/asset/PartnerAssetCreate')),
  },
  {
    key: 'partner.asset.update',
    path: `${APP_PREFIX_PATH}/partner/asset/update`,
    component: React.lazy(() => import('presentation/pages/partner/asset/PartnerAssetUpdate')),
  },
  {
    key: 'partner.asset.view',
    path: `${APP_PREFIX_PATH}/partner/asset/view`,
    component: React.lazy(() => import('presentation/pages/partner/asset/PartnerAssetView')),
  },

  // PARTNER - PRODUCT
  {
    key: 'partner.product.manage',
    path: `${APP_PREFIX_PATH}/partner/product/manage`,
    component: React.lazy(() => import('presentation/pages/partner/product/PartnerProductIndex')),
  },
  {
    key: 'partner.product.create',
    path: `${APP_PREFIX_PATH}/partner/product/create`,
    component: React.lazy(() => import('presentation/pages/partner/product/PartnerProductCreate')),
  },
  {
    key: 'partner.product.update',
    path: `${APP_PREFIX_PATH}/partner/product/update`,
    component: React.lazy(() => import('presentation/pages/partner/product/PartnerProductUpdate')),
  },
  {
    key: 'partner.product.view',
    path: `${APP_PREFIX_PATH}/partner/product/view`,
    component: React.lazy(() => import('presentation/pages/partner/product/PartnerProductView')),
  },

  // PARTNER - MASTER
  {
    key: 'partner.master.manage',
    path: `${APP_PREFIX_PATH}/partner/master/manage`,
    component: React.lazy(() => import('presentation/pages/partner/master/PartnerMasterIndex')),
  },
  {
    key: 'partner.master.cultivation-activity.manage',
    path: `${APP_PREFIX_PATH}/partner/master/cultivation-activity/manage`,
    component: React.lazy(() =>
      import('presentation/pages/partner/master/cultivation-activity/CultivationActivityIndex'),
    ),
  },
  {
    key: 'partner.master.cultivation-activity.create',
    path: `${APP_PREFIX_PATH}/partner/master/cultivation-activity/create`,
    component: React.lazy(() =>
      import('presentation/pages/partner/master/cultivation-activity/CultivationActivityCreate'),
    ),
  },
  {
    key: 'partner.master.cultivation-activity.update',
    path: `${APP_PREFIX_PATH}/partner/master/cultivation-activity/update`,
    component: React.lazy(() =>
      import('presentation/pages/partner/master/cultivation-activity/CultivationActivityUpdate'),
    ),
  },
  {
    key: 'partner.master.cultivation-activity.view',
    path: `${APP_PREFIX_PATH}/partner/master/cultivation-activity/view`,
    component: React.lazy(() =>
      import('presentation/pages/partner/master/cultivation-activity/CultivationActivityView'),
    ),
  },
  {
    key: 'partner.master.cultivation-material.manage',
    path: `${APP_PREFIX_PATH}/partner/master/cultivation-material/manage`,
    component: React.lazy(() =>
      import('presentation/pages/partner/master/cultivation-material/CultivationMaterialIndex'),
    ),
  },
  {
    key: 'partner.master.cultivation-material.create',
    path: `${APP_PREFIX_PATH}/partner/master/cultivation-material/create`,
    component: React.lazy(() =>
      import('presentation/pages/partner/master/cultivation-material/CultivationMaterialCreate'),
    ),
  },
  {
    key: 'partner.master.cultivation-material.update',
    path: `${APP_PREFIX_PATH}/partner/master/cultivation-material/update`,
    component: React.lazy(() =>
      import('presentation/pages/partner/master/cultivation-material/CultivationMaterialUpdate'),
    ),
  },
  {
    key: 'partner.master.cultivation-material.view',
    path: `${APP_PREFIX_PATH}/partner/master/cultivation-material/view`,
    component: React.lazy(() =>
      import('presentation/pages/partner/master/cultivation-material/CultivationMaterialView'),
    ),
  },

  // PARTNER - CONFIGURATION
  {
    key: 'partner.configuration.manage',
    path: `${APP_PREFIX_PATH}/partner/configuration/manage`,
    component: React.lazy(() =>
      import('presentation/pages/partner/configuration/PartnerConfigurationIndex'),
    ),
  },
  {
    key: 'partner.configuration.create',
    path: `${APP_PREFIX_PATH}/partner/configuration/create`,
    component: React.lazy(() =>
      import('presentation/pages/partner/configuration/PartnerConfigurationCreate'),
    ),
  },
  {
    key: 'partner.configuration.update',
    path: `${APP_PREFIX_PATH}/partner/configuration/update`,
    component: React.lazy(() =>
      import('presentation/pages/partner/configuration/PartnerConfigurationUpdate'),
    ),
  },
  {
    key: 'partner.configuration.view',
    path: `${APP_PREFIX_PATH}/partner/configuration/view`,
    component: React.lazy(() =>
      import('presentation/pages/partner/configuration/PartnerConfigurationView'),
    ),
  },

  // MASTER
  {
    key: 'master.manage',
    path: `${APP_PREFIX_PATH}/administrator/master/manage`,
    component: React.lazy(() => import('presentation/pages/administrator/master/MasterIndex')),
  },
  {
    key: 'master.product-category.manage',
    path: `${APP_PREFIX_PATH}/administrator/master/product-category/manage`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/product-category/ProductCategoryIndex'),
    ),
  },
  {
    key: 'master.product-category.create',
    path: `${APP_PREFIX_PATH}/administrator/master/product-category/create`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/product-category/ProductCategoryCreate'),
    ),
  },
  {
    key: 'master.product-category.update',
    path: `${APP_PREFIX_PATH}/administrator/master/product-category/update`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/product-category/ProductCategoryUpdate'),
    ),
  },
  {
    key: 'master.product-category.view',
    path: `${APP_PREFIX_PATH}/administrator/master/product-category/view`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/product-category/ProductCategoryView'),
    ),
  },
  {
    key: 'master.subscription.manage',
    path: `${APP_PREFIX_PATH}/administrator/master/subscription/manage`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/subscription/SubscriptionIndex'),
    ),
  },
  {
    key: 'master.subscription.create',
    path: `${APP_PREFIX_PATH}/administrator/master/subscription/create`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/subscription/SubscriptionCreate'),
    ),
  },
  {
    key: 'master.subscription.update',
    path: `${APP_PREFIX_PATH}/administrator/master/subscription/update`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/subscription/SubscriptionUpdate'),
    ),
  },
  {
    key: 'master.subscription.view',
    path: `${APP_PREFIX_PATH}/administrator/master/subscription/view`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/subscription/SubscriptionView'),
    ),
  },
  {
    key: 'master.bank.manage',
    path: `${APP_PREFIX_PATH}/administrator/master/bank/manage`,
    component: React.lazy(() => import('presentation/pages/administrator/master/bank/BankIndex')),
  },
  {
    key: 'master.bank.create',
    path: `${APP_PREFIX_PATH}/administrator/master/bank/create`,
    component: React.lazy(() => import('presentation/pages/administrator/master/bank/BankCreate')),
  },
  {
    key: 'master.bank.update',
    path: `${APP_PREFIX_PATH}/administrator/master/bank/update`,
    component: React.lazy(() => import('presentation/pages/administrator/master/bank/BankUpdate')),
  },
  {
    key: 'master.bank.view',
    path: `${APP_PREFIX_PATH}/administrator/master/bank/view`,
    component: React.lazy(() => import('presentation/pages/administrator/master/bank/BankView')),
  },
  {
    key: 'master.content.manage',
    path: `${APP_PREFIX_PATH}/administrator/master/content/manage`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/content/ContentIndex'),
    ),
  },
  {
    key: 'master.content.create',
    path: `${APP_PREFIX_PATH}/administrator/master/content/create`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/content/ContentCreate'),
    ),
  },
  {
    key: 'master.content.update',
    path: `${APP_PREFIX_PATH}/administrator/master/content/update`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/content/ContentUpdate'),
    ),
  },
  {
    key: 'master.content.view',
    path: `${APP_PREFIX_PATH}/administrator/master/content/view`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/content/ContentView'),
    ),
  },
  {
    key: 'master.location.manage',
    path: `${APP_PREFIX_PATH}/administrator/master/location/manage`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/master/location/LocationIndex'),
    ),
  },
  {
    key: 'master.menu.manage',
    path: `${APP_PREFIX_PATH}/administrator/master/menu/manage`,
    component: React.lazy(() => import('presentation/pages/administrator/master/menu/MenuIndex')),
  },
  {
    key: 'master.menu.create',
    path: `${APP_PREFIX_PATH}/administrator/master/menu/create`,
    component: React.lazy(() => import('presentation/pages/administrator/master/menu/MenuCreate')),
  },
  {
    key: 'master.menu.update',
    path: `${APP_PREFIX_PATH}/administrator/master/menu/update`,
    component: React.lazy(() => import('presentation/pages/administrator/master/menu/MenuUpdate')),
  },
  {
    key: 'master.menu.view',
    path: `${APP_PREFIX_PATH}/administrator/master/menu/view`,
    component: React.lazy(() => import('presentation/pages/administrator/master/menu/MenuView')),
  },

  // SSO - OTP
  {
    key: 'sso.otp.manage',
    path: `${APP_PREFIX_PATH}/administrator/sso/otp/manage`,
    component: React.lazy(() => import('presentation/pages/administrator/sso/otp/OTPIndex')),
  },

  // SSO - ACCOUNT
  {
    key: 'sso.account.manage',
    path: `${APP_PREFIX_PATH}/administrator/sso/account/manage`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/sso/account/AccountIndex'),
    ),
  },
  {
    key: 'sso.account.create',
    path: `${APP_PREFIX_PATH}/administrator/sso/account/create`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/sso/account/AccountCreate'),
    ),
  },
  {
    key: 'sso.account.update',
    path: `${APP_PREFIX_PATH}/administrator/sso/account/update`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/sso/account/AccountUpdate'),
    ),
  },
  {
    key: 'sso.account.view',
    path: `${APP_PREFIX_PATH}/administrator/sso/account/view`,
    component: React.lazy(() => import('presentation/pages/administrator/sso/account/AccountView')),
  },
  {
    key: 'sso.account.change-password',
    path: `${APP_PREFIX_PATH}/administrator/sso/account/change-password`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/sso/account/AccountChangePassword'),
    ),
  },
  {
    key: 'sso.account.menu-access',
    path: `${APP_PREFIX_PATH}/administrator/sso/account/menu-access`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/sso/account/AccountMenuAccess'),
    ),
  },

  // FILE STORAGE
  {
    key: 'filestorage.manage',
    path: `${APP_PREFIX_PATH}/administrator/file-storage/manage`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/file-storage/FileStorageIndex'),
    ),
  },
  {
    key: 'filestorage.view',
    path: `${APP_PREFIX_PATH}/administrator/file-storage/view`,
    component: React.lazy(() =>
      import('presentation/pages/administrator/file-storage/FileStorageView'),
    ),
  },

  // CULTIVATION - SEEDING
  {
    key: 'cultivation.seeding.manage',
    path: `${APP_PREFIX_PATH}/cultivation/seeding/manage`,
    component: React.lazy(() => import('presentation/pages/cultivation/seeding/SeedingIndex')),
  },
  {
    key: 'cultivation.seeding.view',
    path: `${APP_PREFIX_PATH}/cultivation/seeding/view`,
    component: React.lazy(() => import('presentation/pages/cultivation/seeding/SeedingView')),
  },

  // CULTIVATION - UPKEEPING
  {
    key: 'cultivation.upkeeping.manage',
    path: `${APP_PREFIX_PATH}/cultivation/upkeeping/manage`,
    component: React.lazy(() => import('presentation/pages/cultivation/upkeeping/UpkeepingIndex')),
  },
  {
    key: 'cultivation.upkeeping.view',
    path: `${APP_PREFIX_PATH}/cultivation/upkeeping/view`,
    component: React.lazy(() => import('presentation/pages/cultivation/upkeeping/UpkeepingView')),
  },

  // CULTIVATION - HARVESTING
  {
    key: 'cultivation.harvesting.manage',
    path: `${APP_PREFIX_PATH}/cultivation/harvesting/manage`,
    component: React.lazy(() =>
      import('presentation/pages/cultivation/harvesting/HarvestingIndex'),
    ),
  },
  {
    key: 'cultivation.harvesting.view',
    path: `${APP_PREFIX_PATH}/cultivation/harvesting/view`,
    component: React.lazy(() => import('presentation/pages/cultivation/harvesting/HarvestingView')),
  },

  // CULTIVATION - INSPECTION
  {
    key: 'cultivation.inspection.manage',
    path: `${APP_PREFIX_PATH}/cultivation/inspection/manage`,
    component: React.lazy(() =>
      import('presentation/pages/cultivation/inspection/InspectionIndex'),
    ),
  },
  {
    key: 'cultivation.inspection.view',
    path: `${APP_PREFIX_PATH}/cultivation/inspection/view`,
    component: React.lazy(() => import('presentation/pages/cultivation/inspection/InspectionView')),
  },

  // LOGISTIC - SHIPPING
  {
    key: 'logistic.shipping.manage',
    path: `${APP_PREFIX_PATH}/logistic/shipping/manage`,
    component: React.lazy(() => import('presentation/pages/logistic/shipping/ShippingIndex')),
  },
  {
    key: 'logistic.shipping.view',
    path: `${APP_PREFIX_PATH}/logistic/shipping/view`,
    component: React.lazy(() => import('presentation/pages/logistic/shipping/ShippingView')),
  },

  // LOGISTIC - RECEIVING
  {
    key: 'logistic.receiving.manage',
    path: `${APP_PREFIX_PATH}/logistic/receiving/manage`,
    component: React.lazy(() => import('presentation/pages/logistic/receiving/ReceivingIndex')),
  },
  {
    key: 'logistic.receiving.view',
    path: `${APP_PREFIX_PATH}/logistic/receiving/view`,
    component: React.lazy(() => import('presentation/pages/logistic/receiving/ReceivingView')),
  },

  // BUSINESS - SUBSCRIPTION
  {
    key: 'business.subscription.manage',
    path: `${APP_PREFIX_PATH}/business/subscription/manage`,
    component: React.lazy(() =>
      import('presentation/pages/business/subscription/BusinessSubscriptionIndex'),
    ),
  },
  {
    key: 'business.subscription-account.manage',
    path: `${APP_PREFIX_PATH}/business/subscription/account/manage`,
    component: React.lazy(() =>
      import(
        'presentation/pages/business/subscription/subscription-account/SubscriptionAccountIndex'
      ),
    ),
  },
  {
    key: 'business.subscription-account.view',
    path: `${APP_PREFIX_PATH}/business/subscription/account/view`,
    component: React.lazy(() =>
      import(
        'presentation/pages/business/subscription/subscription-account/SubscriptionAccountView'
      ),
    ),
  },
  {
    key: 'business.transaction-plan.manage',
    path: `${APP_PREFIX_PATH}/business/subscription/transaction-plan/manage`,
    component: React.lazy(() =>
      import('presentation/pages/business/subscription/transaction-plan/TransactionPlanIndex'),
    ),
  },
  {
    key: 'business.transaction-plan.view',
    path: `${APP_PREFIX_PATH}/business/subscription/transaction-plan/view`,
    component: React.lazy(() =>
      import('presentation/pages/business/subscription/transaction-plan/TransactionPlanView'),
    ),
  },
  {
    key: 'business.transaction-quota.manage',
    path: `${APP_PREFIX_PATH}/business/subscription/transaction-quota/manage`,
    component: React.lazy(() =>
      import('presentation/pages/business/subscription/transaction-quota/TransactionQuotaIndex'),
    ),
  },
  {
    key: 'business.transaction-quota.view',
    path: `${APP_PREFIX_PATH}/business/subscription/transaction-quota/view`,
    component: React.lazy(() =>
      import('presentation/pages/business/subscription/transaction-quota/TransactionQuotaView'),
    ),
  },
  {
    key: 'business.quota-usage.manage',
    path: `${APP_PREFIX_PATH}/business/subscription/quota-usage/manage`,
    component: React.lazy(() =>
      import('presentation/pages/business/subscription/quota-usage/QuotaUsageIndex'),
    ),
  },
  {
    key: 'business.quota-usage.view',
    path: `${APP_PREFIX_PATH}/business/subscription/quota-usage/view`,
    component: React.lazy(() =>
      import('presentation/pages/business/subscription/quota-usage/QuotaUsageView'),
    ),
  },

  // BUSINESS - ADVERTISING CAMPAIGN
  {
    key: 'business.advertising-campaign.manage',
    path: `${APP_PREFIX_PATH}/business/advertising/manage`,
    component: React.lazy(() =>
      import('presentation/pages/business/advertising/campaign/CampaignIndex'),
    ),
  },
  {
    key: 'business.advertising-campaign.create',
    path: `${APP_PREFIX_PATH}/business/advertising/create`,
    component: React.lazy(() =>
      import('presentation/pages/business/advertising/campaign/CampaignCreate'),
    ),
  },
  {
    key: 'business.advertising-campaign.update',
    path: `${APP_PREFIX_PATH}/business/advertising/update`,
    component: React.lazy(() =>
      import('presentation/pages/business/advertising/campaign/CampaignUpdate'),
    ),
  },
  {
    key: 'business.advertising-campaign.view',
    path: `${APP_PREFIX_PATH}/business/advertising/view`,
    component: React.lazy(() =>
      import('presentation/pages/business/advertising/campaign/CampaignView'),
    ),
  },

  // MERCHANT - MARKETPLACE
  {
    key: 'merchant.marketplace.manage',
    path: `${APP_PREFIX_PATH}/merchant/marketplace/manage`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/marketplace/MerchantMarketplaceIndex'),
    ),
  },
  {
    key: 'merchant.marketplace.create',
    path: `${APP_PREFIX_PATH}/merchant/marketplace/create`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/marketplace/MerchantMarketplaceCreate'),
    ),
  },
  {
    key: 'merchant.marketplace.update',
    path: `${APP_PREFIX_PATH}/merchant/marketplace/update`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/marketplace/MerchantMarketplaceUpdate'),
    ),
  },
  {
    key: 'merchant.marketplace.view',
    path: `${APP_PREFIX_PATH}/merchant/marketplace/view`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/marketplace/MerchantMarketplaceView'),
    ),
  },

  // MERCHANT - EXPEDITION VEHICLE
  {
    key: 'merchant.expedition-vehicle.manage',
    path: `${APP_PREFIX_PATH}/merchant/expedition/vehicle/manage`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/expedition/vehicle/ExpeditionVehicleIndex'),
    ),
  },
  {
    key: 'merchant.expedition-vehicle.create',
    path: `${APP_PREFIX_PATH}/merchant/expedition/vehicle/create`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/expedition/vehicle/ExpeditionVehicleCreate'),
    ),
  },
  {
    key: 'merchant.expedition-vehicle.update',
    path: `${APP_PREFIX_PATH}/merchant/expedition/vehicle/update`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/expedition/vehicle/ExpeditionVehicleUpdate'),
    ),
  },
  {
    key: 'merchant.expedition-vehicle.view',
    path: `${APP_PREFIX_PATH}/merchant/expedition/vehicle/view`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/expedition/vehicle/ExpeditionVehicleView'),
    ),
  },

  // MERCHANT - EXPEDITION VEHICLE
  {
    key: 'merchant.expedition-quotation.manage',
    path: `${APP_PREFIX_PATH}/merchant/expedition/quotation/manage`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/expedition/quotation/ExpeditionQuotationIndex'),
    ),
  },
  {
    key: 'merchant.expedition-quotation.view',
    path: `${APP_PREFIX_PATH}/merchant/expedition/quotation/view`,
    component: React.lazy(() =>
      import('presentation/pages/merchant/expedition/quotation/ExpeditionQuotationView'),
    ),
  },

  // TRADE - COMMODITY
  {
    key: 'trade.commodity.manage',
    path: `${APP_PREFIX_PATH}/trade/commodity/manage`,
    component: React.lazy(() => import('presentation/pages/trade/commodity/TradeCommodityIndex')),
  },
  {
    key: 'trade.commodity.view',
    path: `${APP_PREFIX_PATH}/trade/commodity/view`,
    component: React.lazy(() => import('presentation/pages/trade/commodity/TradeCommodityView')),
  },

  // FINANCE - INVOICE
  {
    key: 'finance.invoice.manage',
    path: `${APP_PREFIX_PATH}/finance/invoice/manage`,
    component: React.lazy(() => import('presentation/pages/finance/invoice/InvoiceIndex')),
  },
  {
    key: 'finance.invoice.view',
    path: `${APP_PREFIX_PATH}/finance/invoice/view`,
    component: React.lazy(() => import('presentation/pages/finance/invoice/InvoiceView')),
  },

  // FINANCE - PAYMENT
  {
    key: 'finance.payment.manage',
    path: `${APP_PREFIX_PATH}/finance/payment/manage`,
    component: React.lazy(() => import('presentation/pages/finance/payment/PaymentIndex')),
  },
  {
    key: 'finance.payment.view',
    path: `${APP_PREFIX_PATH}/finance/payment/view`,
    component: React.lazy(() => import('presentation/pages/finance/payment/PaymentView')),
  },
]
