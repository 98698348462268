import { decryptCrypto, encryptCrypto } from './Encryption'

export const setSession = (data) => {
  const row = {
    value: data.value,
    expires: data.expires ?? null,
  }
  return localStorage.setItem(data.key, encryptCrypto(JSON.stringify(row)))
}

export const getSession = (key, output = 'value') => {
  const data = typeof window !== 'undefined' ? localStorage.getItem(key) : null
  let value = null
  let storage = null
  if (data) {
    storage = decryptCrypto(data)
    if (storage.expires) {
      if (storage.expires > new Date().getTime()) {
        value = storage.value
      } else {
        localStorage.removeItem(key) // Has Been Expired
      }
    } else {
      value = storage.value
    }
  }

  if (value !== null && output === 'storage') {
    return storage
  }

  return value
}

export const removeSession = (key) => {
  localStorage.removeItem(key)
}
